import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import { i18nConfigForTest } from '../../../../tests/i18nConfigForTest';

const { resources, ...configOverrides } =
  process.env.NODE_ENV === 'test'
    ? i18nConfigForTest
    : {
        resources: () =>
          resourcesToBackend((language, namespace, callback) => {
            import(
              `../../../../../public/locales/${language}/${namespace}.json`
            )
              .then((resources) => {
                callback(null, resources);
              })
              .catch((error) => {
                callback(error, null);
              });
          }),
      };

const config = {
  // don't pass resources directly if a function is provided
  resources: typeof resources === 'function' ? undefined : resources,
  debug: Boolean(process.env.REACT_APP_I18N_DEBUGGING),
  returnObjects: true,
  ns: ['common'],
  defaultNS: 'common',
  fallbackLng: 'en',
  react: {
    transEmptyNodeValue: 'Loading',
  },
  interpolation: {
    escapeValue: false,
  },
  ...configOverrides,
};

if (typeof resources === 'function') {
  i18n
    .use(resources())
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(config);
} else {
  i18n.use(LanguageDetector).use(initReactI18next).init(config);
}

export default i18n;
