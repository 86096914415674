import { alpha } from '@mui/material/styles';

const styles = (color) => {
  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 767,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    typography: {
      fontFamily: ['"Montserrat"', 'Helvetica', 'sans-serif'].join(','),
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            color: color.semantic.neutral.main,
            letterSpacing: 0,
            '&.MuiTypography-h1': {
              'h1&': {
                color: color.semantic.neutral[500],
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '32px',
              },
            },
            '&.MuiTypography-h2': {
              'h2&': {
                color: color.semantic.neutral[500],
                fontSize: '18px',
                fontWeight: '700',
                lineHeight: '24px',
              },
            },
          },
        },
        variants: [
          {
            props: { variant: 'body2' },
            style: () => ({
              fontSize: 16,
            }),
          },
        ],
      },
      MuiPaper: {
        styleOverrides: {
          elevation: {
            boxShadow: '0 1px 4px 0 rgba(2, 22, 51, 0.20)',
          },
          rounded: {
            borderRadius: '3px',
          },
          root: ({ theme }) => ({
            '&.MuiCard-root': {
              backgroundColor: color.semantic.neutral[0],
              boxShadow: '0 1px 4px 0 rgba(2, 22, 51, 0.20)',
              border: 'none',
              padding: '24px !important',
              [theme.breakpoints.down('sm')]: {
                padding: '16px !important',
                marginBottom: '16px',
              },
            },
            '&:not(.MuiPaper-elevation)': {
              boxShadow: 'none',
              borderRadius: '8px',
              padding: '16px',
              border: `1px solid ${color.semantic.neutral[400]}`,
              background: color.semantic.neutral[0],
              '&.Mui-selected': {
                borderColor: theme.palette.primary.main,
                background: theme.palette.primary.light,
              },
            },
            '&.MuiPaper-elevation3': {
              boxShadow: 'none',
              '.MuiPaper-outlined': {
                boxShadow: '0 1px 4px 0 rgba(2, 22, 51, 0.20)',
                border: 'none',
                backgroundColor: color.semantic.neutral[0],
              },
            },
            '> .MuiCardContent-root': {
              padding: 0,
            },
            '> .MuiCardHeader-root + .MuiCardContent-root': {
              paddingTop: '8px',
            },
            '> .MuiStack-root + .MuiCardContent-root': {
              paddingTop: '24px',
            },
            '&#domain-details': {
              '.MuiInputBase-root': {
                paddingLeft: 0,
                input: {
                  color: color.semantic.neutral.main,
                  border: 'none',
                },
                '.MuiInputAdornment-root': {
                  '&:first-child': {
                    display: 'none',
                  },
                  '.MuiSvgIcon-root': {
                    color: theme.palette.info.main,
                  },
                },
              },
            },
            '&.add-site-option-card > .MuiStack-root': {
              margin: '0',
            },
          }),
        },
      },
      MuiStack: {
        styleOverrides: {
          root: {
            '&.ds-quick-connect': {
              flexWrap: 'wrap',
              gap: 16,
              '.MuiListItem-root': {
                width: 'auto',
                flexGrow: 1,
                marginLeft: 0,
                '.MuiListItemText-root': {
                  marginTop: '4px',
                  marginBottom: '4px',
                  '.MuiTypography-root > .MuiStack-root': {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    '> .MuiButton-text': {
                      paddingLeft: 0,
                      paddingRight: 0,
                    },
                  },
                },
              },
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&.MuiOutlinedInput-root': {
              '&.Mui-focused': {
                '.MuiOutlinedInput-notchedOutline': {
                  borderWidth: '1px',
                  borderColor: theme.palette.primary.main,
                },
                '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                  borderWidth: '1px',
                  borderColor: color.semantic.error.main,
                },
              },
              '&:hover': {
                '&:not(.Mui-focused, .Mui-error)': {
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: color.semantic.neutral[600],
                  },
                },
              },
            },
            'input[type=text], input[type=password], .input-box': {
              '&, &:focus': {
                border: 'none',
              },
            },
          }),
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            display: 'none',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            padding: '24px',
            '&.ds-mobile-card': {
              '> .MuiCardHeader-root': {
                padding: 0,
                backgroundColor: color.semantic.neutral[0],
                '.MuiCardHeader-title': {
                  fontSize: '16px',
                  lineHeight: '24px',
                  fontWeight: 'normal',
                  color: color.semantic.neutral.dark,
                },
              },
              '> .MuiCardContent-root': {
                '.MuiBox-root p': {
                  padding: 0,
                  textOverflow: 'unset',
                  overflow: 'unset',
                  whiteSpace: 'unset',
                },
                p: {
                  paddingTop: 0,
                  paddingBottom: '8px',
                  maxWidth: '100%',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                },
                '.MuiTypography-root, label': {
                  color: color.semantic.neutral[500],
                },
              },
            },
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: '0',
            '&:last-child': {
              paddingBottom: 0,
            },
            '.MuiTypography-h2': {
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '32px',
              [theme.breakpoints.down('sm')]: {
                fontSize: '20px',
              },
            },
            '+ .MuiCardContent--root': {
              paddingTop: '24px',
            },
            '.MuiCardHeader-content > .MuiCardHeader-title': {
              display: 'inline',
            },
          }),
          action: {
            margin: 0,
            '.MuiButtonBase-root': {
              margin: 0,
            },
          },
          title: {
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '32px',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: 0,
            '&:last-child': {
              paddingBottom: 0,
            },
            '> .MuiStack-root': {
              '> .MuiTypography-h3:first-child': {
                color: color.semantic.neutral.main,
                fontSize: '24px',
                fontWeight: 700,
                lineHeight: '32px',
                marginBottom: '8px',
                [theme.breakpoints.down('sm')]: {
                  fontSize: '20px',
                },
                '+ .MuiTypography-body2': {
                  marginTop: 0,
                },
              },
            },
          }),
        },
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },
        variants: [
          {
            props: { variant: 'contained', color: 'primary' },
            style: ({ theme }) => ({
              color: color.semantic.neutral[0],
              background: theme.palette.primary.main,
              boxShadow: `-4px -4px 0px 0px ${color.semantic.neutral.light}`,
              '&:hover': {
                boxShadow: 'none',
                background: color.semantic.neutral.light,
                color: theme.palette.primary.main,
              },
              'a&': {
                color: color.semantic.neutral[0],
              },
            }),
          },
          {
            props: { variant: 'outlined', color: 'primary' },
            style: ({ theme }) => ({
              color: theme.palette.primary.main,
              background: color.semantic.neutral[0],
              boxShadow: `-4px -4px 0px 0px ${color.semantic.neutral[100]}`,
              '&:hover': {
                boxShadow: 'none',
                background: color.semantic.neutral.light,
                borderColor: color.semantic.neutral.light,
              },
              'a&': {
                color: theme.palette.primary.main,
              },
            }),
          },
          {
            props: { variant: 'contained', color: 'error' },
            style: ({ theme }) => ({
              color: theme.palette.primary.main,
              background: color.semantic.neutral[0],
              boxShadow: `-4px -4px 0 0 ${color.semantic.neutral[100]}`,
              '&:not(.Mui-disabled)': {
                border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
              },
              '&:hover': {
                boxShadow: 'none',
                background: color.semantic.neutral.light,
                borderColor: color.semantic.neutral.light,
              },
            }),
          },
        ],
        styleOverrides: {
          root: {
            fontSize: 16,
            borderRadius: 0,
            fontWeight: '600 !important',
            letterSpacing: 0,
            whiteSpace: 'nowrap',
            '&:not(.MuiButton-text)': {
              height: '40px',
            },
            '&[data-testid="rename-site"]': {
              fontSize: 14,
            },
            '&.ds-button__back': {
              color: color.semantic.neutral[400],
              lineHeight: '24px',
              padding: 0,
              height: '24px',
              minWidth: '24px',
            },
          },
          text: {
            '&#basic-button': {
              color: color.semantic.neutral.main,
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&.ds-app-bar': {
              background: color.semantic.neutral[50],
              width: '100%',
              boxSizing: 'border-box',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 10,
              paddingTop: '52px',
              [theme.breakpoints.down('sm')]: {
                minHeight: '217px',
              },
              '.MuiToolbar-root': {
                maxWidth: '1216px',
                paddingLeft: '40px',
                paddingRight: '40px',
                marginLeft: 'auto',
                marginRight: 'auto',
                [theme.breakpoints.down('md')]: {
                  maxWidth: '100%',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                },
                [theme.breakpoints.down('sm')]: {
                  paddingLeft: '16px',
                  paddingRight: '16px',
                },
                h1: {
                  fontSize: '24px',
                  fontWeight: '700',
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '20px',
                  },
                },
                'a.MuiButtonBase-root:not(.MuiTab-root.Mui-selected, .MuiTab-root:hover)':
                  {
                    color: color.semantic.neutral[400],
                  },
                '.MuiLoadingButton-root': {
                  [theme.breakpoints.down(767)]: {
                    width: '100%',
                  },
                },
              },
            },
          }),
        },
      },
      MuiTabs: {
        defaultProps: {
          variant: 'scrollable',
          scrollButtons: false,
        },
      },
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: ({ theme }) => ({
            paddingLeft: 0,
            paddingRight: 0,
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '24px',
            paddingBottom: '14px',
            marginRight: '32px',
            letterSpacing: 0,
            textTransform: 'capitalize',
            color: color.semantic.neutral[400],
            minWidth: 'auto',
            '&.Mui-selected': {
              color: theme.palette.primary.main,
            },
          }),
          textColorPrimary: ({ theme }) => ({
            color: color.semantic.neutral[400],
            borderBottom: '2px solid transparent',
            'a&': {
              color: color.semantic.neutral[400],
            },
            '&:hover': {
              color: theme.palette.primary.main,
              borderBottom: `2px solid ${theme.palette.primary.main}`,
            },
          }),
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            background: color.semantic.neutral[0],
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            '&.MuiInputLabel-animated:not(.MuiInputLabel-shrink)': {
              transform: 'translate(14px, 12px) scale(1)',
              color: color.semantic.neutral[300],
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          button: ({ theme }) => ({
            color: theme.palette.primary.main,
            fontSize: 14,
            textDecoration: 'none',
            fontWeight: 600,
            lineHeight: '24px',
            textTransform: 'uppercase',
          }),
          root: ({ theme }) => ({
            '.MuiTypography-root': {
              color: theme.palette.info.main,
              '&:hover': {
                color: theme.palette.info.main,
                textDecoration: 'underline',
              },
            },
          }),
        },
      },
      MuiScopedCssBaseline: {
        styleOverrides: {
          root: {
            'section.main': {
              '> .MuiContainer-root, > .MuiBox-root': {
                paddingLeft: 0,
                paddingRight: 0,
              },
              '.MuiBox-root[data-testid="docroot-component"]': {
                padding: 0,
                background: 'transparent',
              },
            },
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            height: '20px',
            padding: '4px',
            border: `1px solid ${color.semantic.neutral[300]}`,
            background: color.semantic.neutral.light,
          },
          bar: ({ theme }) => ({
            top: '2px',
            bottom: '2px',
            background: theme.palette.primary.main,
          }),
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&.ds-websites': {
              '> .MuiPaper-root': {
                borderRadius: 0,
                boxShadow: 'none',
                padding: '0 2px !important',
                margin: '0 -2px',
                '> .MuiCardHeader-root': {
                  paddingTop: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                },
                '> .MuiCardContent-root': {
                  paddingBottom: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                  '.MuiPaper-root.MuiCard-root': {
                    '.MuiCardActionArea-root': {
                      '&:hover p.MuiTypography-body2': {
                        textDecoration: 'underline',
                      },
                      '.MuiTypography-body1': {
                        color: color.semantic.neutral[600],
                      },
                    },
                    '.MuiButtonBase-root': {
                      fontSize: 14,
                    },
                  },
                },
              },
              '.MuiInputBase-root': {
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: '1px',
                  background: color.semantic.neutral[300],
                  transition: 'background .2s cubic-bezier(.2,.3,.25,.9)',
                },
                '&:after': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: '2px',
                  transition: 'transform 0.3s ease-out',
                  background: theme.palette.primary.main,
                  transform: 'scaleX(0)',
                },
                '&:hover': {
                  '&:before': {
                    borderColor: color.semantic.neutral[600],
                  },
                },
                '&.Mui-focused': {
                  '&:after': {
                    transform: 'scaleX(1)',
                  },
                },
                '&.MuiOutlinedInput-root': {
                  paddingRight: 0,
                  borderRadius: 0,
                },
                input: {
                  border: 'none',
                  fontSize: '16px',
                  lineHeight: '24px',
                  height: '40px',
                  color: color.semantic.neutral[600],
                },
                fieldset: {
                  border: 'none',
                },
                '.MuiInputAdornment-root': {
                  marginLeft: 0,
                  '.MuiButtonBase-root .MuiSvgIcon-root': {
                    fontSize: '2rem',
                  },
                },
              },
            },
            '&.quick-links-button': {
              paddingTop: '8px',
              '&:first-child': {
                paddingTop: '40px',
              },
            },
            '.MuiPaper-root.MuiCard-root': {
              '.MuiCardActionArea-root': {
                'h3.MuiTypography-h3': {
                  fontSize: '18px',
                  lineHeight: '24px',
                },
              },
            },
            '&.hosting-plan-details-block': {
              'p.MuiTypography-body2': {
                color: `${color.semantic.neutral[500]} !important`,
                fontWeight: 'normal',
              },
              'a.MuiTypography-root.MuiLink-root': {
                '&, > .MuiTypography-root': {
                  color: `${color.semantic.primary.main} !important`,
                  textDecoration: 'none',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                },
              },
            },
            '&.ds-overview-card': {
              gap: '16px',
            },
            'label.MuiFormControlLabel-root': {
              marginTop: '1px',
            },
            '.hosting-overview-container': {
              'a.MuiTypography-root.MuiLink-root': {
                '&, > .MuiTypography-root': {
                  color: `${color.semantic.primary.main} !important`,
                  textDecoration: 'none',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                },
              },
            },
          }),
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            minWidth: '160px',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: 14,
            color: color.semantic.neutral.main,
            '&.MuiButtonBase-root': {
              minHeight: '40px',
              paddingLeft: '24px',
              paddingRight: '24px',
            },
            'a&': {
              color: color.semantic.neutral.main,
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: '58px',
            height: '29px',
            padding: 0,
            marginLeft: '24px',
            borderRadius: '20px',
          },
          switchBase: ({ theme }) => ({
            padding: 0,
            top: '1px',
            left: '1px',
            '&.MuiButtonBase-root.Mui-checked': {
              transform: 'translateX(29px)',
              color: color.semantic.neutral[0],
              '+ .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: `${theme.palette.primary.main} !important`,
              },
            },
          }),
          thumb: {
            height: '27px',
            width: '27px',
          },
          track: {
            opacity: 1,
            backgroundColor: `${color.semantic.neutral[300]} !important`,
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            borderRadius: '0 !important',
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            border: `1px solid ${color.semantic.neutral.light}`,
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            background: color.semantic.neutral.light,
            color: color.semantic.neutral[400],
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontWeight: 400,
            'th&': {
              color: color.semantic.neutral[400],
              padding: '8px 16px',
            },
          },
        },
      },
      MuiToggleButton: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: ({ theme }) => ({
            color: color.semantic.neutral.main,
            border: 'none',
            '&:hover': {
              backgroundColor: color.semantic.neutral[0],
            },
            '&.Mui-selected': {
              color: theme.palette.primary.main,
              '&, &:hover': {
                backgroundColor: color.semantic.neutral[0],
              },
            },
            '.MuiSvgIcon-root': {
              fontSize: '2rem',
            },
          }),
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            '&[data-testid="card-header"]': {
              color: color.semantic.neutral[500],
            },
          },
        },
      },
    },
  };
};

export default styles;
