import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';

import {
  useHostingAdvancedServer,
  useHostingInfoDiskUsage,
} from '@newfold/huapi-js';
import { HostingInfoDiskUsage200 } from '@newfold/huapi-js/src/index.schemas';

import CTB from '~/components/CTB';
import CustomPieChart from '~/components/CustomPieChart';
import { convertStorageValuesToNumbers } from '~/components/CustomPieChart/utils';
import useAccount from '~/hooks/useAccount';
import { useBrandInfo } from '~/hooks/useBrandInfo';
import {
  productTypes,
  useCtbProductInfo,
  useCtbPurchaseStatusQuery,
} from '~/hooks/useCtbProductInfo';
import useFeature from '~/hooks/useFeature';
import useHostingInfo from '~/hooks/useHostingInfo';
import useHostingPlanDetails from '~/hooks/useHostingPlanDetails';
import useResponsive from '~/hooks/useResponsive';

import CardLoader from './CardLoader';

function getUsedPercentage(data: HostingInfoDiskUsage200) {
  const { disklimit, diskused, filesused, fileslimit } = data!;

  const diskUsedValue = convertStorageValuesToNumbers(diskused!);
  const diskLimitValue = convertStorageValuesToNumbers(disklimit!);

  let diskUsagePercentage =
    diskLimitValue > 0
      ? Math.round((diskUsedValue / diskLimitValue) * 100)
      : diskLimitValue;

  let fileCountPercentage =
    // @ts-ignore
    fileslimit === 'Unlimited' ? 0 : Math.round((filesused / fileslimit) * 100);
  return { diskUsagePercentage, fileCountPercentage };
}

const DiskUsageChart = () => {
  const { t } = useTranslation('hosting', { keyPrefix: 'usageChartContainer' });
  const { id: accountId } = useAccount();
  const theme = useTheme();
  const { diskUsageLearnMoreUrl } = useBrandInfo();
  const [hasSites] = useFeature('sites');
  const [isPlesk, , clientReady] = useFeature('plesk');
  const { isDisabled } = useHostingInfo();
  const { contactLink, optimizeLink } = useBrandInfo();
  const [canUpgrade] = useFeature('hosting_upgrade');
  const { isBluehostOnlineStorePlan } = useHostingPlanDetails(accountId);
  const hashFragment = '#click-to-buy-HOSTING_UPGRADE';
  const { pathname, hash } = useLocation();
  const { isMobile } = useResponsive();
  const [showServerControls, , isClientReady] = useFeature('server_controls');
  const { data: serverData } = useHostingAdvancedServer(accountId, {
    query: {
      refetchOnWindowFocus: false,
      enabled: !!accountId && isClientReady && showServerControls,
    },
  });

  const serverStatus = serverData?.data?.status;

  //CTB upgrade status
  const productInfo = useCtbProductInfo(productTypes.HOSTING_UPGRADE);
  const { hasPurchasedRecently } = useCtbPurchaseStatusQuery(
    productInfo.purchaseQueryKey,
  );
  const isCTBDeepLinked = hash.includes(hashFragment);
  const doNotShowDiskUsage = isPlesk || !clientReady;
  const isRestrictDiskUsageCall = isDisabled ? false : !doNotShowDiskUsage;
  const {
    data,
    isFetching,
    refetch: refetchDiskData,
    isError,
  } = useHostingInfoDiskUsage(accountId, {
    query: {
      enabled: !!accountId && isRestrictDiskUsageCall,
    },
  });
  if (!hasSites && (serverStatus !== 'active' || hasPurchasedRecently)) {
    return null;
  }

  if (doNotShowDiskUsage) return null;
  const hostingDiskUsageData = data?.data;
  if (isFetching)
    return (
      <CardLoader
        title={t('cardTitle')}
        diskChartTitle={t('diskUsageTitle')}
        fileUsageChartTitle={t('filesCountTitle')}
      />
    );
  const { disklimit, disklastupdated, diskused, filesused, fileslimit } =
    hostingDiskUsageData!;

  const handleContactSupport = () => {
    window.open(contactLink, '_blank');
  };
  const handleOptimizeYourWebsite = () => {
    window.open(optimizeLink, '_blank');
  };
  const handleRefreshData = () => {
    refetchDiskData();
  };
  const { diskUsagePercentage, fileCountPercentage } = getUsedPercentage(
    hostingDiskUsageData!,
  );

  // Determine whether to show dynamic actions like optimize, contact, upgrade when either of storage is >=90%
  const isAdditionalActionsNeeded =
    diskUsagePercentage >= 90 || fileCountPercentage >= 90;

  const diskChartData = [
    {
      label: 'Used',
      legendLabel: t('usedSpaceLabel'),
      value: diskused!,
    },
    {
      label: 'Total',
      legendLabel: t('totalSpaceLabel'),
      value: disklimit!,
    },
  ];
  const filesChartData = [
    {
      label: 'Used',
      legendLabel: t('usedSpaceLabel'),
      value: filesused!,
    },
    {
      label: 'Total',
      legendLabel: t('totalSpaceLabel'),
      value: fileslimit!,
    },
  ];

  const actions = (
    <>
      {pathname?.includes('/resource-usage') ? (
        <Typography variant="body2">
          <Link
            target="_blank"
            href={diskUsageLearnMoreUrl}
            style={{ textDecoration: 'none' }}
          >
            {t('learnMoreLink')}
          </Link>
        </Typography>
      ) : (
        <Button
          href="/resource-usage"
          data-testid="resource-usage-action"
          variant="outlined"
          fullWidth={isMobile()}
        >
          {t('primaryAction')}
        </Button>
      )}
    </>
  );
  const formatLastUpdated = moment(disklastupdated).format('DD/MM/yyyy h:mm:a');
  return (
    <Card>
      <CardContent>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          mb={2}
        >
          <Typography variant="h2">{t('cardTitle')}</Typography>
          {actions}
        </Stack>
        {isError ? (
          <Typography>{t('errorTxt')}</Typography>
        ) : (
          <>
            {disklastupdated && (
              <Box display="flex" mb={2} alignItems="center">
                <Typography variant="body2">
                  {`${t('lastUpdatedDate', {
                    lastupdated: formatLastUpdated,
                  })}`}
                </Typography>
                <IconButton
                  sx={{ color: theme.palette.info.main }}
                  onClick={handleRefreshData}
                >
                  <CachedRoundedIcon />
                </IconButton>
              </Box>
            )}
            <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
              <Box flex={1}>
                <Paper elevation={1}>
                  <CustomPieChart
                    data={diskChartData}
                    cardTitle={t('diskUsageTitle')}
                    type="storage"
                    cardHeaderTooltip={t('diskUsageTooltip')}
                    margin={{ left: 80 }}
                    slotProps={{ legend: { hidden: true } }}
                    isLoading={isFetching}
                    width={180}
                  />
                </Paper>
              </Box>

              <Box flex={1}>
                <Paper elevation={1}>
                  <CustomPieChart
                    data={filesChartData}
                    cardTitle={t('filesCountTitle')}
                    cardHeaderTooltip={t('filesCountTooltip')}
                    margin={{ left: 80 }}
                    slotProps={{ legend: { hidden: true } }}
                    isLoading={isFetching}
                    width={180}
                  />
                </Paper>
              </Box>
            </Stack>
          </>
        )}
        {/* To show additional actions like optimize, contact, upgrade */}
        {isAdditionalActionsNeeded && (
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            mt={2}
            alignItems={{ xs: 'space-between', sm: 'center' }}
            spacing={2}
            justifyContent="space-between"
          >
            <Typography variant="h3">{t('runningOutOfSpaceTxt')}</Typography>
            <Box
              gap={1}
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
            >
              <Button variant="outlined" onClick={handleOptimizeYourWebsite}>
                {t('optimizeWebsite')}
              </Button>
              {canUpgrade && !isBluehostOnlineStorePlan && !isCTBDeepLinked && (
                <CTB product="HOSTING_UPGRADE">
                  {/* @ts-expect-error */}
                  <Button variant="outlined">{t('upgradePlan')}</Button>
                </CTB>
              )}
              <Button variant="outlined" onClick={handleContactSupport}>
                {t('contact')}
              </Button>
            </Box>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

export default DiskUsageChart;
