import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { useMFEContext } from '../../../../components/MFEProvider';
import { getRenewalDateText } from '../../../../utils/hosting-renewal-status';
import { isValidDate } from '../../../../utils/isValidDate';

const RenewalSettings = () => {
  const {
    // @ts-expect-error
    data: { hostingData, renewalCenterLink },
    // @ts-expect-error
    callbacks: { handleClickToBuy },
    // @ts-expect-error
    hostingStatus: {
      renewalStatus: { isExpired },
    },
  } = useMFEContext();
  const { t } = useTranslation('hosting');

  const expiryDate = hostingData?.expiryDate;
  const hasAutoRenew = hostingData?.hasAutoRenew;

  const renewalDateText = t(
    `renewal.date.${getRenewalDateText(hasAutoRenew, isExpired)}`,
  );

  // reformat date "04/04/2020" as "April 04, 2020"
  const prettyExpDate = moment(expiryDate).format('MMMM DD, YYYY');

  const validExpiryDate = isValidDate(expiryDate);

  const showRenew = renewalCenterLink || handleClickToBuy?.HOSTING_RENEW;

  const handleAutoRenewSwitch = () => {
    if (renewalCenterLink) window.open(renewalCenterLink, '_blank');
    if (typeof handleClickToBuy?.HOSTING_AUTO_RENEW === 'function')
      handleClickToBuy.HOSTING_AUTO_RENEW();
  };

  return (
    (expiryDate || hasAutoRenew) && (
      <>
        <Grid direction="column" item xs={12} md={6}>
          {/* Auto-Renew */}
          <Grid item xs={4}>
            <Typography variant="body2">
              {t('renewal.autoRenew.header')}
            </Typography>
          </Grid>

          <Grid item xs={8}>
            {hasAutoRenew ? (
              <Typography
                variant="body2"
                color="success.main"
                fontWeight={600}
                mt={0.5}
              >
                {t('renewal.autoRenew.enabled')}
              </Typography>
            ) : (
              <FormControlLabel
                labelPlacement="start"
                sx={{ ml: 0, mt: '-4px' }}
                slotProps={{ typography: { variant: 'body2' } }}
                control={
                  <Switch
                    checked={hasAutoRenew}
                    onChange={handleAutoRenewSwitch}
                    // color={hasAutoRenew ? 'success' : 'error'} // TODO: dependent on future styling
                    sx={{
                      '& .MuiSwitch-track': {
                        backgroundColor: (theme) =>
                          hasAutoRenew
                            ? theme.palette.primary.main
                            : theme.palette.error.main,
                        opacity: 1,
                      },
                    }}
                  />
                }
                label={
                  hasAutoRenew
                    ? t('renewal.autoRenew.on')
                    : t('renewal.autoRenew.off')
                }
              />
            )}
          </Grid>
        </Grid>
        <Grid direction="column" item xs={12} md={6}>
          {/* Expires */}
          <Grid item xs={4}>
            {validExpiryDate && (
              <Typography variant="body2">{renewalDateText}</Typography>
            )}
          </Grid>

          <Grid item xs={8} mt={0.5}>
            {validExpiryDate && (
              <Typography variant="body2">
                {prettyExpDate}{' '}
                {showRenew && (
                  <Link
                    href={renewalCenterLink}
                    onClick={handleClickToBuy?.HOSTING_RENEW}
                    target="_blank"
                    sx={{ ml: 2, fontWeight: 'bold' }}
                  >
                    {t('renewal.renewLink')}
                  </Link>
                )}
              </Typography>
            )}
          </Grid>
        </Grid>
      </>
    )
  );
};

export default RenewalSettings;
